import React, {Component} from "react"
import Cards from "./Cards"

class Projects extends Component{
    render(){
        return (
            <main className="relative">
                <Cards />
            </main>  
            );
    }
}

export default Projects;